<template>
  <div>
    <div class="row mb-2">
      <div class="col-4">
        <app-select-ym
          v-model="time"
          :initiated="true"
          @input="setTime()"
        ></app-select-ym>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4>{{ noInitiatedProforms }}</h4>
            <p>Proformas no iniciadas</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4>{{ initiatedProforms }}</h4>
            <p>Proformas iniciadas</p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h4>{{ total }}</h4>
            <p>Total</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
import _ from "lodash";
import { myMoment } from "../../utils/myMoment";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    // onlyToday: {}
  },

  data: () => ({
    time: null,
    noInitiatedProforms: 0,
    initiatedProforms: 0,
    total: 0,
    iniDate: null,
    endDate: null
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    this.getList();
  },

  methods: {
    setRange(time1, time2) {
      this.iniDate = time1;
      this.endDate = time2;
      this.getList();
    },
    asyncGetList: _.debounce(vm => {
      DentalClinicService.getProformsReport({
        // ...this.time,
        // onlyToday: this.onlyToday
        iniDate: vm.iniDate,
        endDate: vm.endDate
      }).then(res => {
        vm.noInitiatedProforms = res.noInitiatedProforms;
        vm.initiatedProforms = res.initiatedProforms;
        vm.total = res.total;
      });
    }, 300),
    getList() {
      this.asyncGetList(this);
    },
    getRangeTime() {
      var time = myMoment(
        `${this.time.year}-${this.time.month}-01`,
        "YYYY-MM-DD"
      );
      // this.iniDate = time.format("YYYY-MM-DD");
      // this.endDate = time.add(1, "months").format("YYYY-MM-DD");
      return {
        // iniDate: time.format("YYYY-MM-DD"),
        iniDate: time,
        // endDate: time.add(1, "months").format("YYYY-MM-DD")
        endDate: time.clone().add(1, "months")
      };
    },
    setTime() {
      var time = this.getRangeTime();
      this.iniDate = time.iniDate.format("YYYY-MM-DD");
      this.endDate = time.endDate.format("YYYY-MM-DD");
      this.$emit("newRange", {
        iniDate: this.iniDate,
        endDate: this.endDate
      });
      this.getList();
    }
  }
};
</script>

<style></style>

<template>
  <select class="custom-select" v-model="valueChild">
    <option :value="undefined"></option> 
    <option v-for="(l,index) in list" :value="index" :key="index">{{l}} </option> 
  </select>
</template>

<script>
import {mapState} from "vuex"

export default {
  computed: {
    valueChild: {
     get() { return this.value; },
     set(val) { this.$emit('input', val); }
    },
    ...mapState({
      list: s => s.config.dental.odontogram.groups
    })
  },
  props: {
    value: {}
  }
}

</script>

<style>

</style>

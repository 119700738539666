<template>
  <div>
    <h4>PROFORMAS</h4>

    <div class="mb-3">
      <head-report
        ref="headReport"
        @newRange="
          customRange = 'thisMonth';
          asyncGetList();
        "
      ></head-report>
      <!-- <app-checkbox v-model="onlyToday">Solo de hoy</app-checkbox> -->
      <div class="mt-2 custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline1"
          name="customRadioInline1"
          class="custom-control-input"
          value="onlyToday"
          v-model="customRange"
        />
        <label class="custom-control-label" for="customRadioInline1"
          >Solo de hoy</label
        >
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline2"
          name="customRadioInline1"
          class="custom-control-input"
          value="thisWeek"
          v-model="customRange"
        />
        <label class="custom-control-label" for="customRadioInline2"
          >Esta semana</label
        >
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline3"
          name="customRadioInline1"
          class="custom-control-input"
          value="lastWeek"
          v-model="customRange"
        />
        <label class="custom-control-label" for="customRadioInline3"
          >La semana pasada</label
        >
      </div>
      <!-- <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id="customRadioInline4"
          name="customRadioInline1"
          class="custom-control-input"
          value="thisMonth"
          v-model="customRange"
        />
        <label class="custom-control-label" for="customRadioInline4"
          >Este mes</label
        >
      </div> -->
    </div>

    <app-table-registers
      ref="tr"
      :getList="getList"
      :exeGetChildAfterMount="true"
    >
      <template slot="tl">
        <div class="col form-inline">
          <button
            class="btn btn-primary dropdown-toggle mr-1"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-plus"></i> Nuevo
          </button>
          <MenuLinks></MenuLinks>
          <SelectGroup v-model="group"></SelectGroup>
          <!-- <app-checkbox
            class="ml-1"
            v-model="used"
            placeholder="Iniciados"
          ></app-checkbox> -->
        </div>
      </template>
      <template slot="table">
        <thead>
          <tr>
            <th>FECHA</th>
            <th>CLIENTE</th>
            <th>TELEFONO</th>
            <th>FECHA DE VENCIMIENTO</th>
            <th>MONTO</th>
            <th>INICIO TRATAMIENTO</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list.data" :key="l.id">
            <td>{{ l.created_at }}</td>
            <td>{{ l.client_name }}</td>
            <td>{{ l.cell_phone_number }}</td>
            <td>{{ l.expiration }}</td>
            <td>
              <!-- <select class="custom-select custom-select-sm">
                <option v-for="l1 in l.totals" :key="l1.money_code + l.id">
                  <app-span-money :quantity="l1.total" :moneyCode="l1.money_code"></app-span-money>
                </option>
              </select> -->
            </td>
            <!-- <td class="text-center">{{ l.used ? "SI" : "NO" }}</td> -->
            <td>
              <app-badge-status
                :value="l.initiated"
                on="inicio"
                off="No Inicio"
              ></app-badge-status>
            </td>
            <td>
              <div class="btn-group">
                <router-link
                  href="#"
                  class="btn btn-sm btn-light"
                  :to="groupLink(l)"
                >
                  <i class="fa fa-link"></i> Ver
                </router-link>
                <a
                  href="#"
                  class="btn btn-sm btn-light"
                  v-show="!l.deleted_at && !l.initiated"
                  @click.prevent="
                    $refs.preDel.show();
                    $refs.preDel.val = l.id;
                    reasonToDelete = '';
                    proformClientId = l.client_id;
                  "
                >
                  <i class="fa fa-trash"></i> Eliminar
                </a>
              </div>
              <!-- <button
                class="btn btn-sm btn-light"
                @click="$refs.proform.loadProformId(l.id).then(() => { $refs.dUseProf.show(); }); reg.name = ''; reg.proform_id = l.id;"
              >
                <i class="fa fa-plus"></i>
              </button>-->
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="preDel">
      <div class="form-group">
        <label for="">Razon para eliminar proforma</label>
        <input
          type="text"
          class="form-control form-control-alternative"
          v-model="reasonToDelete"
        />
      </div>
      <div class="form-group">
        <label for="">Proforma para transferencia</label>
        <SelectTreatment
          :initiated="0"
          :client_id="proformClientId"
          v-model="planToTransfer"
        ></SelectTreatment>
      </div>
      <div class="form-group">
        <button
          :disabled="!(reasonToDelete.length > 4)"
          @click="
            deleteReg($refs.preDel.val, reasonToDelete);
            $refs.preDel.hide();
          "
          class="btn btn-primary"
        >
          Eliminar
        </button>
        <button @click="$refs.preDel.hide()" class="btn btn-secondary ml-1">
          Cancelar
        </button>
      </div>
    </app-modal-basic>

    <!-- <app-modal-yn
      ref="preDel"
      @yes="deleteReg($refs.preDel.val, reasonToDelete)"
    >
      <div class="form-group">
        <label for="">Razon para eliminar proforma</label>
        <input
          type="text"
          class="form-control form-control-alternative"
          v-input-focused
          v-model="reasonToDelete"
        />
      </div>
    </app-modal-yn> -->
  </div>
</template>

<script>
import { DentalClinicService } from "../service";
import MenuLinks from "./MenuLinks";
// import ProForm from "./ProForm";
// import SelectEmployee from "../../admin-module/employees/Select";
import SelectGroup from "./SelectGroup";
import headReport from "./head-report";
import { myMoment } from "../../utils/myMoment";
import { debounce } from "lodash";
import SelectTreatment from "../treatments/Select";

export default {
  components: {
    MenuLinks,
    // ProForm,
    // SelectEmployee,
    SelectGroup,
    headReport,
    SelectTreatment
  },
  data: () => ({
    list: {},
    reg: {},
    used: false,
    group: undefined,
    // onlyToday: false,
    // thisWeek: false,
    // lastWeek: false,
    customRange: "",
    reasonToDelete: "",
    planToTransfer: 0,
    proformClientId: 0
  }),
  computed: {
    config() {
      return this.$store.state.config.dental
        ? this.$store.state.config.dental.proform
        : {};
    }
  },
  watch: {
    customRange() {
      this.asyncGetList();
    },
    // thisWeek() {
    //   this.asyncGetList();
    // },
    // lastWeek() {
    //   this.asyncGetList();
    // },
    used() {
      this.$refs.tr.gl();
    },
    group() {
      this.$refs.tr.gl();
    }
  },
  methods: {
    deleteReg(id, reason) {
      DentalClinicService.deleteProform(id, reason, this.planToTransfer).then(
        () => {
          this.$refs.preDel.hide();
          this.asyncGetList();
        }
      );
    },
    groupLink(l) {
      // if (l.group == 4) {
      //   return "/dental-clinic/proform-othodontic/" + l.id;
      // }
      return "/dental-clinic/proforms/" + l.id;
    },
    initTreatment() {
      let details = [];
      for (let l of this.$refs.proform.details) {
        details.push({
          product_id: l.product_id,
          discount: l.discount,
          custom_price: l.custom_price,
          proform_detail_id: l.id,
          teeth_history_id: l.teeth_history_id
        });
      }
      DentalClinicService.saveTreatmentPlan({
        ...this.reg,
        details
      }).then(res => {
        this.$router.push(
          "/dental-clinic/patients/" +
            this.$refs.proform.client_id +
            "/treatment-plans/" +
            res
        );
      });
    },
    asyncGetList() {
      this.subReload(this);
    },
    subReload: debounce(vm => {
      // console.log("quee")
      vm.$refs.tr.gl();
    }, 800),
    getList({ page, search }) {
      let params = {
        page,
        search,
        useds: this.used ? 1 : undefined,
        group: this.group
      };
      var iniDate = myMoment();
      var endDate = myMoment();
      iniDate.minutes(0);
      iniDate.hours(0);
      if (this.customRange == "onlyToday") {
        endDate = iniDate.clone().add(1, "days");
      } else if (this.customRange == "thisWeek") {
        iniDate = iniDate.clone().add(-iniDate.day(), "days");
        endDate = iniDate.clone().add(7, "days");
      } else if (this.customRange == "lastWeek") {
        iniDate = iniDate.clone().add(-7 - iniDate.day(), "days");
        endDate = iniDate.clone().add(7, "days");
      } else if (this.customRange == "thisMonth") {
        var time = this.$refs.headReport.getRangeTime();
        iniDate = time.iniDate;
        endDate = time.endDate;
      } else {
        iniDate = null;
        endDate = null;
      }
      if (this.customRange != "thisMonth" && iniDate) {
        this.$refs.headReport.setRange(
          iniDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        );
      }
      params["iniDate"] = iniDate ? iniDate.format("YYYY-MM-DD") : null;
      params["endDate"] = endDate ? endDate.format("YYYY-MM-DD") : null;
      // console.log(iniDate.format("MM-DD"), endDate.format("MM-DD"));
      return new Promise(rsv => {
        DentalClinicService.getProforms(params).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
